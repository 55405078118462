import { Letter } from "./components/Letter"
import { useEffect } from "react"

import $ from 'jquery'

export const LetterSlogan = (text) => {
    useEffect(() => {
        // let spanArray = $('.section2 .container .slogan span');
        // let spanArray2 = $('.section6 .container .slogan span');

        const handleScroll = () => {
            let topEdge = $('.section2').offset().top;
            let bottomEdge = $('.section2').offset().top + $('.section2 .container').height() ;

            let spanArray = $('.section2 .slogan span');

            let numberOfSpans = $('.section2 .slogan span').length;

            let delta = (bottomEdge - topEdge) / numberOfSpans;


            if ($(window).scrollTop() > topEdge && $(window).scrollTop() < bottomEdge) {
                let scrollDelta = $(window).scrollTop() - topEdge;

                for (let index = 0; index < spanArray.length; index++) {
                    const element = spanArray[index];
                    if (index < Math.round(scrollDelta / delta)) {
                        $(element).addClass('active')
                    } else {
                        $(element).removeClass('active')
                    }
                }
            } else if ($(window).scrollTop() > bottomEdge){
                for (let index = 0; index < spanArray.length; index++) {
                    const element = spanArray[index];
                    $(element).addClass('active')
                }
            } else if ($(window).scrollTop() < topEdge){
                for (let index = 0; index < spanArray.length; index++) {
                    const element = spanArray[index];
                    $(element).removeClass('active')
                }
            }
        }

        const handleScroll2 = () => {
            let topEdge2 = $('.section6').offset().top;
            let bottomEdge2 = $('.section6').offset().top + $('.section6 .container').height() ;

            let spanArray2 = $('.section6 h2 span');

            let numberOfSpans2 = $('.section6 h2 span').length;

            let delta = (bottomEdge2 - topEdge2) / numberOfSpans2;


            if ($(window).scrollTop() > topEdge2 && $(window).scrollTop() < bottomEdge2) {
                let scrollDelta2 = $(window).scrollTop() - topEdge2;

                for (let index = 0; index < spanArray2.length; index++) {
                    const element2 = spanArray2[index];
                    if (index < Math.round(scrollDelta2 / delta)) {
                        $(element2).addClass('active')
                    } else {
                        $(element2).removeClass('active')
                    }
                }
            } else if ($(window).scrollTop() > bottomEdge2) {
                for (let index = 0; index < spanArray2.length; index++) {
                    const element2 = spanArray2[index];
                    $(element2).addClass('active')
                }
            } else if ($(window).scrollTop() < topEdge2) {
                for (let index = 0; index < spanArray2.length; index++) {
                    const element2 = spanArray2[index];
                    $(element2).removeClass('active')
                }
            }
        }

        window.addEventListener('scroll', handleScroll)
        window.addEventListener('scroll', handleScroll2)

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('scroll', handleScroll2);
        }
    })

    return (<>
        {text.text.split(' ').map((elem, i) => {
            return <Letter letter={elem} key={i} />
        })}
    </>
    )
}

