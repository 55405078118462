import logoWhite from '../../../img/logo-white.svg';
import logoBlack from '../../../img/logo-black.svg'
import Menu from './components/Menu'
import ThemeSwitch from './components/ThemeSwitch';
import LocalizedLink from "../LocalizedLink";
import { useSelector } from 'react-redux';


const Header = () => {

    const themeColor = useSelector(state => state.languageSlice.themeColor)

    return (
        <header>
            <div className='container'>
                <div className="left">
                    <LocalizedLink path='/' clList='logo' imgSrc={themeColor ? logoBlack : logoWhite}></LocalizedLink>
                </div>
                <ThemeSwitch />
                <Menu />
            </div>
        </header>
    );
}

export default Header;
