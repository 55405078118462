//libs
import { Routes, Route, Navigate } from "react-router-dom";
import i18n from "i18next";
import { useEffect } from 'react';
import { useNavigate } from "react-router-dom";

//utils & contants
import { useDispatch, useSelector } from 'react-redux';

//partial & pages
import Header from './partials/Header'
import Footer from './partials/Footer'
import Home from './pages/MainPage';
import SocialLinksContainer from './partials/SocialLinks'

import Services from './pages/Services';
import { ServicePageContainer } from './pages/ServicePageContainer';

import { BlogList } from './pages/BlogList';
import BLogPageContainer from './pages/BlogPageContainer';

import CasesPage from './pages/CasesPage/CasesPage';
import SingleCasePageContainer from './pages/CasesPage/SingleCasePageContainer';

import LanguageSwitcher from './partials/LanguageSwitcher';
import { setlanguage } from '../redux/slices/language';
import { PageNotFound } from "./pages/PageNotFound";
import ScrollToTop from './partials/ScrollToTop';
import Team from './pages/team/Team';
import { TeamSinglePageContainer } from './pages/team/TeamSinglePageContainer';
import HeaderHelmet from "./ui/HeaderHelmet";

const App = () => {
    
    const navigate = useNavigate();
    useEffect(() => {
        const { pathname } = window.location;
        if (pathname !== pathname.toLowerCase()) {
            navigate(pathname.toLowerCase())
        }
    }, [navigate]);

    const themeColor = useSelector(state => state.languageSlice.themeColor)
    const dispatch = useDispatch()

    if (window.location.pathname.split('/')[1] === 'ua') {
        i18n.changeLanguage('ua')
        dispatch(setlanguage('ua'))
    } else {
        i18n.changeLanguage('en')
        dispatch(setlanguage('en'))
    }

    return (
        <div className={themeColor ? 'light dr' : 'dr'}>
            <Header />
            <LanguageSwitcher />
            <SocialLinksContainer />
            <ScrollToTop />
            <Routes>

                <Route path='/' element={<Home />} />
                <Route path="/en/" element={<Home  />} />
                <Route path="/ua/" element={<Home />} />

                <Route path="/services" element={<Services />} />
                <Route path="/en/services" element={<Services />} />
                <Route path="/ua/services" element={<Services />} />

                <Route path="/blog" element={<BlogList />} />
                <Route path="/en/blog" element={<BlogList />} />
                <Route path="/ua/blog" element={<BlogList />} />

                <Route path="/cases" element={<CasesPage helmetHomePage={false}/>} />
                <Route path="/en/cases" element={<CasesPage helmetHomePage={false}/>} />
                <Route path="/ua/cases" element={<CasesPage helmetHomePage={false}/>} />


                <Route path="/blog/:id" element={<BLogPageContainer />} />
                <Route path="/en/blog/:id" element={<BLogPageContainer />} />
                <Route path="/ua/blog/:id" element={<BLogPageContainer />} />

                <Route path="/services/:id" element={<ServicePageContainer />} />
                <Route path="/en/services/:id" element={<ServicePageContainer />} />
                <Route path="/ua/services/:id" element={<ServicePageContainer />} />

                <Route path="/cases/:id" element={<SingleCasePageContainer />} />
                <Route path="/en/cases/:id" element={<SingleCasePageContainer />} />
                <Route path="/ua/cases/:id" element={<SingleCasePageContainer />} />
{/* 
                <Route path="/team" element={<Team />} />
                <Route path="/en/team" element={<Team />} />
                <Route path="/ua/team" element={<Team />} />

                <Route path="/team/:id" element={<TeamSinglePageContainer />} />
                <Route path="/en/team/:id" element={<TeamSinglePageContainer />} />
                <Route path="/ua/team/:id" element={<TeamSinglePageContainer />} /> */}


                <Route path="/404" element={<PageNotFound />} />
                <Route path="*" element={<Navigate to='/404' />} />
            </Routes>

            <Footer />
        </div>
    )
}

export default App
