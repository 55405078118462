import { Link } from 'react-router-dom'
import {useTranslation} from "react-i18next";
import i18n from 'i18next'
const BlogThumbnail = ({ e }) => {
    const { t } = useTranslation();
    return (
        <Link className='item' to={`${i18n.language === 'en' ? '' : '/ua'}/blog/${e?.url}`}>
            <div className="thumbnail">
                <img src={e?.thumbnail} alt='thumbnail' />
            </div>
            <div className='date'>{e?.date}</div>

            <div className='name'>{e?.title}</div>

            <div className='readmore'>
                <img alt='arrow' src={require('../../../../img/arrow-right-orange-icon.svg').default} />
                {t('rm')}
            </div>
        </Link>
    )
}

export default BlogThumbnail
