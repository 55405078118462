import ResponseComponent from "../partials/ResponseComponent";
import HeaderHelmet from "../ui/HeaderHelmet";

export const ServicePage = ({data}) => {

    return (
        <div className="service" key={data.name} data-section='services' id="services" >
            <HeaderHelmet name={'menu-services'}/>
            <div className="service-main">
                <div className="container">
                    <h1>{data.name}</h1>
                </div>
            </div>
            <div className='service-body'>
                <div className='container' >
                <ResponseComponent  htmlContent={data.content}/>

                </div>
            </div>

        </div>
    )
}
