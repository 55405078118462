import { Link } from 'react-router-dom';
import i18n from "i18next";

const CasesLink = ({ element }) => {
    const handleMouseOver = (event, color) => {
        event.target.style.color = color;
    }

    const hoverColor = element.hover ? element.hover : '#E9E6E6'; // Use the hover color if provided, otherwise use initial color

    return (
        <>
            <Link to={`${i18n.language === 'en' ? '' : '/ua'}/cases/${element.url}`} className='item' key={`key_props_${element.id}`}>
                <h3
                    onMouseOver={(event) => handleMouseOver(event, hoverColor)}
                    onMouseOut={(event) => handleMouseOver(event, '#B4B4B4')}
                >
                    {element.name}
                </h3>
                <div className={'img-wrapper'}>
                    <img src={element.bigImage} alt='caseImage' />
                </div>
            </Link>
        </>
    )

}


export default CasesLink;