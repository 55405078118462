import { Link } from "react-router-dom";
import i18n from 'i18next'

const MPCILink = ({path, text, clList, imgSrc, name, mainPageContent}) => {

    return (
        <Link className={clList} to={`${i18n.language === 'en' ? '' : '/ua'}${path}`}>
            <div className='container'>
                <p>{name}</p>
                <span>{mainPageContent}</span>
            </div>
        </Link>
    )
}

export default MPCILink;
