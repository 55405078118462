import i18n from '../../i18n/index'
import { useDispatch } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { setlanguage } from '../../redux/slices/language';




function LanguageSwitcher() {
    
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const switchLanguageHandler = (lang) =>  {

       

        let path = window.location.pathname;
        let prefix = path.split('/')[1];
        if (prefix === 'ua'){
            let newPath = path.replace(prefix, '').slice(1);
            navigate(newPath)

        } else {
            let newPath = '/ua' + path;
            navigate(newPath)
        }

        i18n.changeLanguage(lang);
        dispatch(setlanguage(lang))
    }


    return (
        <div className="lang">
            <p className={i18n.language === 'ua' ? 'active' : ''} onClick={(e) => switchLanguageHandler('ua')}>UA</p>
            <span>/</span>
            <p className={i18n.language === 'en' ? 'active' : ''} onClick={(e) => switchLanguageHandler('en')}>ENG</p>
        </div>
    );
}

export default LanguageSwitcher;
