import MoreBlog from '../partials/MoreBlog/MoreBlog';
import HeaderHelmet from '../ui/HeaderHelmet';


const BlogPage = ({ data }) => {


    return (
        <div className="blog" data-section='blog' id="blog">
             <HeaderHelmet name={'menu-blog'}/>
            <div className="blog-main">
                <div className="container">
                    <img className='blogimage' alt='blogimage' src={data?.thumbnail} />
                    <h1 className='blogName'>{data?.title}</h1>
                    <div className='date'>{data?.date}</div>
                </div>
            </div>


            <div className='blog-body'>
                <div className='container' dangerouslySetInnerHTML={{ __html: data?.content }}>

                </div>
            </div>

            <MoreBlog dataUrl={data.url} />
        </div>
    )
}

export default BlogPage;