import { useSelector } from 'react-redux';
import { getCases } from '../../../api/request';
import { useQuery } from 'react-query';
import CasesLink from './CasesLink';
import { Navigate } from 'react-router';
import { Preloader } from '../Preloader';
import HeaderHelmet from '../../ui/HeaderHelmet';

const CasesPage = ({helmetHomePage}) => {

    let additionalClass = window.location.pathname === '/cases' ? 'mtm' : ''

    const language = useSelector(state => state.languageSlice.language)

    const fetchDataService = async () => {
        try{
            const response = await getCases(language)
            const data = Object.values(response.data);
            return data
        }
        catch(error){
            throw new Error('Failed to fetch data')
        }
    }

    const { data, isLoading, isError } = useQuery(
        ['cases', language],
        () => fetchDataService(language),
        {
            keepPreviousDta: true,
            refetchOnWindowFocus: false
        }

    )
    if(isError) {
        console.error('error:', isError )
        return <Navigate to={'/404'} />

    }
    if(isLoading) return <Preloader />



    return (
        <section className={`section5 ${additionalClass}`} data-section='cases' id="cases">
             <HeaderHelmet name={'menu-cases'} homepage={helmetHomePage}/>
            <div className='container'>
                {data.map((element, i) => <CasesLink element={element} key={`case_link#${element.id}`}/>)}
            </div>
        </section>
    )
}

export default CasesPage;
