import axios from "axios";

const LESTRO_API = 'https://admin.lestro.com/api';

const apiLestro = axios.create({
    baseURL: LESTRO_API,
    headers: {
       'X-Requested-With': 'XMLHttpRequest',
    },
});

const requestLang = (language) => {
    return language === 'en' ? '/en' : ''
}


export const getBlog = async (language) => {
    const rqstLng = requestLang(language)
    try{
        const url = '/posts' + rqstLng
        const response = await apiLestro.get(url);
        return response;
    }
    catch(error){
        console.error('Error fetching blog:', error);
        throw error;
    }
}
export const getOneBlog = async (language, dataUrl) => {

    const rqstLng = requestLang(language)
    try{
        const url = '/posts' + dataUrl + rqstLng;
        const response = await apiLestro.get(url);
        return response;
    }
    catch(error){
        console.error('Error fetching blog:', error);
        throw error;
    }
}


export const getServices = async (language) => {
    const rqstLng = requestLang(language)
    try{
        const url = '/services-new' + rqstLng
        const response = await apiLestro.get(url);
        return response;
    }
    catch(error){
        console.error('Error fetching blog:', error);
        throw error;
    }
}
export const getOneService = async (language, dataUrl) => {

    const rqstLng = requestLang(language)
    try{
        const url = '/services-new' + dataUrl + rqstLng;
        const response = await apiLestro.get(url);
        return response;
    }
    catch(error){
        console.error('Error fetching blog:', error);
        throw error;
    }
}




export const getCases= async (language) => {

    const rqstLng = requestLang(language)
    try{
        const url =  '/cases' + rqstLng;
        const response = await apiLestro.get(url);
        return response;
    }
    catch(error){
        console.error('Error fetching blog:', error);
        throw error;
    }
}

export const getOneCase = async (language, dataUrl) => {

    const rqstLng = requestLang(language)
    try{
        const url =  '/cases' + dataUrl + rqstLng;
        const response = await apiLestro.get(url);
        return response;
    }
    catch(error){
        console.error('Error fetching blog:', error);
        throw error;
    }
}
