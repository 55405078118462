import { useSelector } from "react-redux"
import { WeAreEn } from "./WeAreEn"
import { WeAreUa } from "./WeAreUa"

export const WeAreContainer = () => {

    const language = useSelector(state => state.languageSlice.language)

    return (
        <div>
            {language === 'en' ? <WeAreEn /> : <WeAreUa />}
        </div>
    )
}