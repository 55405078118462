import { useQuery } from "react-query";
import { getOneCase } from "../../../api/request";
import { SingleCasePage } from "./SingleCasePage";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Preloader } from "../Preloader";



const SingleCasePageContainer = () => {



    const language = useSelector((state) => state.languageSlice.language)

    const navigate = useNavigate();
    const currentUrl = useLocation().pathname;
    const lastSlashIndex = currentUrl.lastIndexOf('/');
    const cleanedUrl = lastSlashIndex !== -1 ? currentUrl.substring(lastSlashIndex ) : currentUrl;
  
  
    const fetchDataService = async () => {
        try{
            const response = await getOneCase(language, cleanedUrl)
            return response.data
        }
        catch(error){
            throw new Error('Failed to fetch data')
        }
    }
    
    const { data, isLoading, isError} = useQuery(
      [cleanedUrl, language],
      () => fetchDataService(language),
      {
          keepPreviousDta: true,
          refetchOnWindowFocus: false
      }
    )
  
    if(isError){
      console.error(isError)
      return null
    }
    
    if(isLoading === false){
      const receivedObject = data
      if(Object.keys(receivedObject).length === 0){
        navigate('/404')
      }
    }
    if(isLoading) return <Preloader />
  
  
    return <SingleCasePage data={data} />

}


export default SingleCasePageContainer