import { ServicePage } from "./ServicePage";
import { Preloader } from "./Preloader";
import { getOneService } from "../../api/request";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useLocation, useNavigate } from "react-router";
import { useEffect } from "react";


export const ServicePageContainer = () => {

  const language = useSelector((state) => state.languageSlice.language)

  const navigate = useNavigate();
  const currentUrl = useLocation().pathname;
  const lastSlashIndex = currentUrl.lastIndexOf('/');
  const cleanedUrl = lastSlashIndex !== -1 ? currentUrl.substring(lastSlashIndex) : currentUrl;


  const fetchDataService = async () => {
    try {
      const response = await getOneService(language, cleanedUrl)
      return response.data
    }
    catch (error) {
      throw new Error('Failed to fetch data')
    }
  }

  const { data, isLoading, isError } = useQuery(
    [cleanedUrl, language],
    () => fetchDataService(language),
    {
      keepPreviousDta: true,
      refetchOnWindowFocus: false
    }
  )


  useEffect(() => {
    if (!isLoading) {
      const event = new Event('sectionsUpdated');
      window.dispatchEvent(event);
    }
  }, [isLoading, data]);



  if (isError) {
    console.error(isError)
    return null
  }

  if (isLoading === false) {
    const receivedObject = data
    if (Object.keys(receivedObject).length === 0) {
      navigate('/404')
    }
  }
  if (isLoading) return <Preloader />




  return (

    <div >
      <ServicePage data={data} />
    </div>
  )

}
