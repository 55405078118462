import { useDispatch } from "react-redux"
import { setThemeColor } from "../../../../redux/slices/language"

const ThemeSwitch = () => {

    const dispatch = useDispatch()

    const switchThemehandler = () => {
        dispatch(setThemeColor())
    }


    return (
        <div className='mid' onClick={switchThemehandler}>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="15" cy="15" r="14.5" fill="#1E1E1E" stroke="#EBE9E5" />
                <path d="M20.272 15.4038C20.272 23.4632 17.4579 29.1894 11.7005 29.1894C5.94312 29.1894 0.8125 23.4632 0.8125 15.4038C0.8125 7.34444 5.94312 0.811035 11.7005 0.811035C17.4579 0.811035 20.272 7.34444 20.272 15.4038Z" fill="#EBE9E5" />
            </svg>
        </div>
    )
}

export default ThemeSwitch