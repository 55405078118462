import { Helmet } from "react-helmet";
import i18n from 'i18next'
import { useTranslation } from "react-i18next";

const HeaderHelmet = ({ name, homepage }) => {
    const { t } = useTranslation()

    if (homepage) {
        return (
            <Helmet>
                <meta charSet="utf-8" />
                <title>{`LESTRO`}</title>
            </Helmet>
        )
    }

    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>{`LESTRO | ${t(name)}`}</title>
        </Helmet>
    )
};

export default HeaderHelmet;
