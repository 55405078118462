import { useEffect, useRef } from 'react';
import $ from 'jquery'

import {useTranslation} from "react-i18next";

export const WeAreEn = () => {
    const ref1 = useRef(null);
    const we = useRef(null)
    const ar = useRef(null)
    const eEl = useRef(null)
    const wrapper = useRef(null)

    const HandleClick = (e) => {
        if ($(window).width() <= 768) {
            $('.section3 .wrapper .item').find('span').slideUp(300)
            $('.section3 .wrapper .item').removeClass('active')

            $(e.target).closest('.item').find('span').slideDown(300)
            $(e.target).closest('.item').addClass('active')
        }
    }

    useEffect(() => {
        let windowHeight = window.innerHeight,
            elDistanceToTop = window.pageYOffset + ref1.current.getBoundingClientRect().top,
            scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

        // let animationHeight = windowHeight * .3;

        const scrollHandle = () => {
            scrollTop = (window.scrollY !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            let delta = elDistanceToTop - (scrollTop + windowHeight * .4);
            let lam = windowHeight * .3;
            if (window.innerWidth > 768) {
                if (scrollTop + windowHeight * 0.4 <= elDistanceToTop && elDistanceToTop <= scrollTop + windowHeight * 0.9) {
                    we.current.style.transform = `rotate(${delta / lam * 16.877}deg)`;
                    we.current.style.left = `${delta / lam * -6.6550925926}vw`;

                    ar.current.style.top = `${delta / lam * 17.7083333333}vw`;
                    ar.current.style.left = `${23.6689814815 + (1 - (delta / lam)) * (32.6689814815 - 23.6689814815)}vw`;

                    eEl.current.style.top = `${delta / lam * 10.5324074074}vw`;
                    eEl.current.style.transform = `rotate(${delta / lam * -6.788}deg)`;
                    eEl.current.style.left = `${51.3310185185 + (1 - (delta / lam)) * (62.6689814815 - 51.3310185185)}vw`;

                    wrapper.current.style.top = `${delta / lam * 16.2037037037 + 2.5}vw`;
                    wrapper.current.style.transform = `rotate(${delta / lam * -6.05}deg)`;
                    wrapper.current.style.left = `${65.6828703704 + (1 - (delta / lam)) * (72.6689814815 - 65.6828703704)}vw`;
                } else {
                    if (scrollTop + windowHeight * 0.4 >= elDistanceToTop){
                        we.current.style.transform = `rotate(0)`;
                        we.current.style.left = `0vw`;

                        ar.current.style.top = `0`;
                        ar.current.style.left = `32.6561vw`;

                        eEl.current.style.top = `0`;
                        eEl.current.style.transform = `rotate(0)`;
                        eEl.current.style.left = `62.5713vw`;

                        wrapper.current.style.top = `2.52326vw`;
                        wrapper.current.style.transform = `rotate(0)`;
                        wrapper.current.style.left = `72.659vw`;
                    }
                }
            } else {
                if (scrollTop + windowHeight * 0.4 <= elDistanceToTop && elDistanceToTop <= scrollTop + windowHeight * 0.9) {
                    we.current.style.transform = `rotate(${delta / lam * 16.877}deg)`;
                    we.current.style.left = `${delta / lam * -6.6550925926}vw`;

                    ar.current.style.top = `${delta / lam * 23.7209302326 + 6.976744186}vw`;
                    ar.current.style.left = `${39.5348837209 + (1 - (delta / lam)) * (46.6678 - 39.5348837209)}vw`;

                    eEl.current.style.top = `${delta / lam * 10.5324074074 + 6.976744186}vw`;
                    eEl.current.style.transform = `rotate(${delta / lam * -6.788}deg)`;
                    eEl.current.style.left = `${83.2558139535 + (1 - (delta / lam)) * (84.6689814815 - 83.2558139535)}vw`;
                } else {
                    if (elDistanceToTop <= scrollTop + windowHeight * 0.9) {
                        we.current.style.transform = `rotate(0deg)`;
                        we.current.style.left = `0vw`;

                        ar.current.style.top = `7vw`;
                        ar.current.style.left = `46.6678vw`;

                        eEl.current.style.top = `7vw`;
                        eEl.current.style.transform = `rotate(0deg)`;
                        eEl.current.style.left = `84.6676vw`;
                    }
                }
            }

        }
        window.addEventListener('scroll', scrollHandle)

        scrollHandle();

        return () => {
            window.removeEventListener('scroll', scrollHandle);
        }
    })
    const { t } = useTranslation();

    return (
        <section className='section3' ref={ref1}>
            <div className='we' ref={we}>
                <img src={require('../../../img/we.svg').default} alt='we'/>
            </div>
            <div className='ar' ref={ar}>
                <img src={require('../../../img/ar.svg').default} alt='ar'/>
            </div>
            <div className='e' ref={eEl}>
                <img src={require('../../../img/e.svg').default} alt='e'/>
            </div>

            <div className='wrapper' ref={wrapper}>
                <div className='item' onClick={HandleClick}>
                    <p><img src="/images/effective-icon.svg" alt=""/>{t('effective')}</p>
                    <span>{t('effectiveText')}</span>
                </div>

                <div className='item' onClick={HandleClick}>
                    <p><img src="/images/exp-icon.svg" alt=""/>{t('exp')}</p>
                    <span>{t('expText')}</span>
                </div>

                <div className='item' onClick={HandleClick}>
                    <p><img src="/images/sust-icon.svg" alt=""/>{t('sust')}</p>
                    <span>{t('sustText')}</span>
                </div>
            </div>
        </section>
    )
}
