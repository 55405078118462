import { Link } from "react-router-dom";
import i18n from 'i18next'

import { useSelector } from "react-redux";
import { getServices } from "../../api/request";
import { useQuery } from "react-query";
import { Preloader } from "./Preloader";
import HeaderHelmet from "../ui/HeaderHelmet";



const Services = () => {

    const language = useSelector(state => state.languageSlice.language)
    const fetchDataService = async () => {
        try {
            const response = await getServices(language)
            const data = Object.values(response.data);
            return data
        }
        catch (error) {
            throw new Error('Failed to fetch data')
        }
    }

    const { data, isLoading } = useQuery(
        ['services', language],
        () => fetchDataService(language),
        {
            keepPreviousDta: true,
            refetchOnWindowFocus: false
        }

    )

    if (isLoading) return <Preloader />


    return (
        <div className="services" data-section='services' id="services">
            <HeaderHelmet name={'menu-services'}/>
            <div className="container">
                {
                    data.map((service) => <Link to={`${i18n.language === 'en' ? '' : '/ua'}/services${service?.url}`} key={service.id}>
                        {service.name}
                    </Link>)
                }
            </div>
        </div>
    );
}

export default Services;
