import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    language: 'en',
    themeColor : false
}

export const languageSlice = createSlice({
    name: 'language',
    initialState,
    reducers: {
        setlanguage: (state, action) => {

            state.language = action.payload
        },
        setThemeColor : (state, action) => {
            state.themeColor = !state.themeColor
        }
    }
})


export const {setlanguage, setThemeColor} = languageSlice.actions
export default languageSlice.reducer