export const en = {
    "menu-about": "about",
    "menu-services": "services",
    "menu-cases": "cases",
    "menu-clients": "clients",
    "menu-blog": "blog",
    "menu-contact": "contacts",
    "menu-team": "team",
    "creative": "is a creative",
    "goodThings": "GOOD things",
    "digitalStudio": "Digital Production",
    "sol": "We develop high-quality digital solutions for all your needs",
    "slo": "Every brand deserves a decent representation in the new digital world. We know all the right codes and web magic to make sure you get the best digital product.",
    "downloadCredentials": "download credentials",
    "effective": "Cost-efficiency",
    "effectiveText": "We are the first digital-production on the market and we know how to make it",
    "exp": "Experience",
    "expText": "Over 1000 successfully completed projects",
    "sust": "Sustainability",
    "sustText": "More than 13 years of activity on the market",
    "wd": "Web development",
    "wdText": "Digital solutions from design to final publishing and technical support",
    "md": "Mobile",
    "mdText": "iOS and Android products",
    "cb": "Chatbots",
    "cbText": "Simple and complex automated business solutions",
    "lp": "Landing pages",
    "lpText": "Effective products for marketing and advertising",
    "banners": "Banners",
    "bannersText": "We design and code advertising materials",
    "ts": "Technical support",
    "tsText": "We solve current problems of our clients connected with their digital products",
    "endSlogan": "We love our products and appreciate our customers. We work with inexhaustible enthusiasm in different categories, with different brands and agencies. We work quickly, efficiently and effectively.",
    "lm": "Learn More",
    "close": "CLOSE",
    "addr1": "UKRAINE, KYIV BEHTEREVSKIY LANE, 4B,",
    "addr2": "OFFICE 101",
    "sendMessage": "SEND us a message",
    "rm": "Read more",
    "errorEmpty": "Please fill this field.",
    "errorIncorrectMail": "Please fill email correctly.",
    "errorLess": "Less than 15 characters.",
    "name": "Name",
    "subject": "Subject",
    "message": "Message",
    "dnd": "Drag and Drop File",
    "uf": "Upload file",
    "send": "SEND",
    "goto": "Go to",
    "seeonmap": "See on map",
    "formSent": "Sended",
    "brands" : "Brands",
    "agencies" : "Agencies",
    "rights": "All rights reserved",
    "PageNotFound" : "Page not found",
    "GoToHomePage": "Go to home page",
    "teamHeading": "Our team",
    "teamPhilosophy":"OUR PHILOSOPHY  ",
    "teamTitle": "Behind every product we create is a team that strives to impress. We build projects that arise spontaneously with passion and talent."
}
