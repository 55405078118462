import { MainPageServicesItem } from "./components/MainPageServicesItem";
import { getServices } from "../../../api/request";
import { useSelector } from "react-redux";

import { useQuery } from "react-query";
import { Preloader } from "../../pages/Preloader";
import { Navigate } from "react-router";

export const MainPageServices = () => {

    
    const language = useSelector(state => state.languageSlice.language)

    const fetchDataService = async () => {
        try{
            const response = await getServices(language)
            const data = Object.values(response.data);
            return data
        }
        catch(error){
            throw new Error('Failed to fetch data')
        }
    }

    const { data, isLoading, isError } = useQuery(
        ['services', language],
        () => fetchDataService(language),
        {
            keepPreviousDta: true,
            refetchOnWindowFocus: false
        }

    )

    if (isLoading) return <Preloader />
    if(isError){
        console.error(isError)
        return <Preloader />
    }

    if(!data){
        return <Navigate to={'/404'}/>
    }

    return (
        <section className='section4' data-section={'#services'} id="services">
            {
                data.map((service, index)=> <MainPageServicesItem key={index} content={service} isMobile={window.innerWidth < 768} />)
            }
        </section>
    );
};
