import React, {useRef, useState} from "react";
import Slider from "react-slick";

export default function SimpleSlider({imagesList, imagesListMobile}) {
    const [currentSlide, setCurrentSlide] = useState(0);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        variableWidth: true,
        arrows: false,
        beforeChange: (current, next) => {
            setCurrentSlide(next)
        },
    };
    const slider = useRef(null);
    const sliderMobile = useRef(null);

    const handleImageClick = (index) => {
        if (slider.current) {
            slider.current.slickGoTo(index);
        }
    };
    const handleImageClickMobile = (index) => {
        if (sliderMobile.current) {
            sliderMobile.current.slickGoTo(index);
        }
    };

    return (
        <>
            <div className={`desktopSlider ${!imagesList?.length? 'dpn': ''}`}>
                <Slider ref={slider} {...settings}>
                    {
                        imagesList?.map((e, i) => {
                            return (
                                <div key={i} onClick={() => handleImageClick(i)} className={`item ${currentSlide !== i ? 'ho' : ''}`}>
                                    <img src={e} alt="case-image"/>
                                </div>
                            )
                        })
                    }
                </Slider>

                <div className={'arrows'}>
                    <div onClick={() => slider?.current?.slickPrev()} className={'arrow prevArrow'}><img src="/images/prev-arrow.svg" alt=""/></div>
                    <div onClick={() => slider?.current?.slickNext()} className={'arrow nextArrow'}><img src="/images/next-arrow.svg" alt=""/></div>
                </div>
            </div>
            <div className={`mobileSlider ${!imagesList?.length? 'mobileSliderDesktop': ''}`}>
                <Slider ref={sliderMobile} {...settings}>
                    {
                        imagesListMobile?.map((e, i) => {
                            return (
                                <div key={i} onClick={() => handleImageClickMobile(i)} className={`item ${currentSlide !== i ? 'ho' : ''}`}>
                                    <img src={e} alt='case-slider'/>
                                </div>
                            )
                        })
                    }
                </Slider>

                <div className={'arrows'}>
                    <div onClick={() => sliderMobile?.current?.slickPrev()} className={'arrow prevArrow'}><img src="/images/prev-arrow.svg" alt=""/></div>
                    <div onClick={() => sliderMobile?.current?.slickNext()} className={'arrow nextArrow'}><img src="/images/next-arrow.svg" alt=""/></div>
                </div>
            </div>
        </>
    );
}