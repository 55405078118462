import { useSelector } from "react-redux"
import BlogThumbnail from "../partials/MoreBlog/components/BlogThumbnail"
import { getBlog } from "../../api/request"
import { useQuery } from "react-query"
import { Navigate } from "react-router"
import { Preloader } from "./Preloader"
import HeaderHelmet from "../ui/HeaderHelmet"

export const BlogList = () => {

    const language = useSelector(state => state.languageSlice.language)

    const fetchDataService = async () => {
        try{
            const response = await getBlog(language)
            const data = Object.values(response.data);
            return data
        }
        catch(error){
            throw new Error('Failed to fetch data')
        }
    }

    const { data, isLoading, isError} = useQuery(
        ['bloglist', language],
        () => fetchDataService(language),
        {
            keepPreviousDta: true,
            refetchOnWindowFocus: false
        }

    )

    if (isLoading) return <Preloader />
    if(isError){
        console.error(isError)
        return <Preloader />
    }

    if(!data){
        return <Navigate to={'/404'}/>
    }



    return (
        <div className="blog-list" data-section='blog' id="blog">
            <HeaderHelmet name={'menu-blog'}/>
            <div className="container">
                {data.map((e, i) => {
                    return <BlogThumbnail key={i} e={e} />
                })}
            </div>
        </div>
    )
}