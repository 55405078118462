import '../../styles/modules/Preloader.css'
import {ReactComponent as SunSvg     } from '../../img/sun-icon-yellow.svg'

export const Preloader = () => {

    return (
        <div className='preloader'>
            <SunSvg className='icon'/>
        </div>
    )
}
