import React from 'react';
import i18n from "i18next";
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { I18nextProvider } from 'react-i18next'
import { Provider } from 'react-redux';
import { store } from './redux/root';
import { QueryClient, QueryClientProvider } from 'react-query';

//styles and component
import './fonts/Futura/fonts.css'
import './styles/reset.scss';
import './styles/index.scss';

//components
import App from './components/App';


let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

setTimeout(() => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}, 400);

const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(

    <I18nextProvider i18n={i18n}>
        <BrowserRouter>
            <Provider store={store}>
                <QueryClientProvider client={queryClient}>
                    <App />
                </QueryClientProvider>
            </Provider>
        </BrowserRouter>
    </I18nextProvider>
);
