import {useTranslation} from "react-i18next";

import CaseSlider from './CaseSlider'
import ResponseComponent from "../../partials/ResponseComponent";
import HeaderHelmet from "../../ui/HeaderHelmet";
export const SingleCasePage = ({data }) => {







    const { t } = useTranslation();
    return (
        <div className="case" data-section='cases' id="cases">
            
            <HeaderHelmet name={'menu-cases'}/>
            <div className="case-main">
                <div className="container">
                    <img className='caseimage' alt='blogimage' src={data?.pageImage} />
                    <h1 className='caseName'>{data?.name}</h1>

                    <a rel="noreferrer" href={data?.externalLink} target={'_blank'} className="ext-link">{t('goto')}</a>
                </div>
            </div>


            <div className='case-body'>
                <div className='container'>
                    <ResponseComponent htmlContent= {data?.content} />
                </div>

                <CaseSlider imagesList={data?.gallery} imagesListMobile={data?.galleryMob} />

                <div className='container'>
                    <ResponseComponent htmlContent= {data?.content_after} />
                </div>
            </div>
        </div>
    )
}
