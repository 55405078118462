import { useSelector } from 'react-redux'
import BlogThumbnail from './components/BlogThumbnail'
import { getBlog } from '../../../api/request'
import { useQuery } from 'react-query'
import { Preloader } from '../../pages/Preloader'
import { Navigate } from 'react-router'


const MoreBlog = ({dataUrl}) => {

    const language = useSelector(state => state.languageSlice.language)

    const fetchDataService = async () => {
        try {
            const response = await getBlog(language)
            const data = Object.values(response.data);
            return data
        }
        catch (error) {
            throw new Error('Failed to fetch data')
        }
    }
    const { data, isLoading, isError } = useQuery(
        ['bloglist', language],
        () => fetchDataService(language),
        {
            keepPreviousDta: true,
            refetchOnWindowFocus: false
        }

    )
    if (isLoading) return <Preloader />
    if (isError) {
        console.error(isError)
        return <Preloader />
    }

    if (!data) {
        return <Navigate to={'/404'} />
    }


  const uniqueIds = new Set();

  const uniqueData = data.filter((element) => {
    if( dataUrl !== element.url){
        uniqueIds.add(element.id);
        return true;
    }
    return false;
  });



    return <section className='section8' data-section='blog' id="blog">
        <div className='container'>
            <div className='wrapper'>
                {uniqueData.length > 0 && <BlogThumbnail e={uniqueData[0]} />}
                {uniqueData.length > 0 && <BlogThumbnail e={uniqueData[1]} />}
            </div>
        </div>
    </section>
}

export default MoreBlog