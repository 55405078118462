import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
export const PageNotFound = () => {
    const { t } = useTranslation();

    return (
        <div className={'page-404'}>
            <h3>404</h3>
            <p>{t('PageNotFound')}</p>

            <Link to={'/'}>
                <img src="/images/next-arrow.svg" alt=""/>
                {t('GoToHomePage')}
            </Link>
        </div>
    )
}
