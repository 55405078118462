import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import {en} from './en.js'
import {ua} from './ua.js'


const resources = {
  en : {
    translation : en
  },
  ua : {
    translation : ua
  }
}

i18n.use(initReactI18next)
.init({
  resources,
  languages: ['en'],
  fallbackLng: 'en',
  interpolation: {
      escapeValue: false
  },
  detection: {
    order: ['path'],
    lookupFromPathIndex: 0
}
})


export default i18n

