import Footer from "./Footer"

const FooterContainer = () => {
    const showPopup = () => {
    }

    return <Footer showPopup={showPopup} />
}


export default FooterContainer;