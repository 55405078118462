//base
import { configureStore } from "@reduxjs/toolkit";
//slices
import languageSlice from "./slices/language";


export const store = configureStore({
    reducer: {
        languageSlice
    },
})