import { useState, useRef } from 'react';
import $ from 'jquery'


import axios from 'axios';
import {useTranslation} from "react-i18next";

function Footer({ showPopup }) {
    const [dragActive, setDragActive] = useState(false);
    let fileInput = useRef();
    let hoverArea = useRef(null)
    let form = useRef(null);
    let popupTriggerButton = useRef(null);
    let textarea = useRef(null)

    let nameRef = useRef(null)
    let emailRef = useRef(null)
    let subjectRef = useRef(null)
    let messageRef = useRef(null)
    let actualForm = useRef(null)

    let nameIn = useRef(null)
    let emailIn = useRef(null)
    let subjectIn = useRef(null)
    let messageIn = useRef(null)
    // let fileIn = useRef(null)

    let succedBlock = useRef(null)

    const [name, setName] = useState('');
    const [mail, setMail] = useState('');
    const [subject, setSubject] = useState('');
    const [message, setMessage] = useState('');
    const [files, setFiles] = useState(null);

    const showFn = () => {
        $(form.current).fadeToggle(300)
    }

    const handleDragOver = (e) => {
        e.preventDefault();

        hoverArea.current.classList.add('active')
    }

    const handleDrop = (e) => {
        e.preventDefault();
        setFiles(e.dataTransfer.files);

    }

    const handleTextarea = (e) => {
        if (textarea.current.value.length >= 26) {
            textarea.current.classList.add('shrink')
        } else {
            textarea.current.classList.remove('shrink')
        }

        setMessage(e.target.value)
    }

    const handeDragLeave = (e) => {
        e.preventDefault();
        hoverArea.current.classList.remove('active')
    }

    if (files !== null && Array.from(files).length === 0) {
        setFiles(null)
    }

    const validateEmail = (e) => {
        if (/\S+@\S+\.\S+/.test(e.target.value)) {
            setMail(e.target.value)
        }
    }

    const formSent = () => {
        showFn()
        nameIn.current.value = ""
        subjectIn.current.value = ""
        emailIn.current.value = ""
        textarea.current.value = ""
        fileInput.current.value = ""
        setFiles('');

        setTimeout(() => {
            $(succedBlock.current).fadeIn(300)
            setTimeout(() => {
                $(succedBlock.current).fadeOut(300).css('display', 'flex')
            }, 1000);
        }, 300);
    }

    const submitForm = () => {
        let errors = [];

        if (name.length === 0) {
            nameRef.current.classList.add('empty');
            errors.push('name');
        } else {
            nameRef.current.classList.remove('empty');
        }

        if (mail.length === 0) {
            emailRef.current.classList.add('empty');
            errors.push('mail');
        } else {
            emailRef.current.classList.remove('empty');
        }

        if (subject.length === 0) {
            subjectRef.current.classList.add('empty');
            errors.push('subject');
        } else {
            subjectRef.current.classList.remove('empty');
        }

        if (message.length < 15) {
            messageRef.current.classList.add('empty');
            errors.push('message');
        } else {
            messageRef.current.classList.remove('empty');
        }

        if (errors.length > 0) {
            // Handle errors, maybe display a message or something
            console.error('Errors found:', errors);
            return;
        }

        let formData = new FormData();

        formData.append('name', name);
        formData.append('email', mail);
        formData.append('subject', subject);
        formData.append('message', message);
        if (document.querySelector('#cf-chl-widget-g4vwq_response')) {
            formData.append('cf-turnstile-response', document.querySelector('#cf-chl-widget-g4vwq_response').value);
        }
        formData.append('file', document.querySelector('[type="file"]').files[0]);

        axios.post('https://admin.lestro.com/api/sent', formData,
            {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(function (response) {
                formSent();
            })
            .catch(function (error) {
                console.error(error);
            });
    }


    const { t } = useTranslation();

    return (
        <footer id='footer' data-section={'footer'}>
            <div className="container">
                <div className="top">
                    <div className="left">
                        {t('addr1')} <br></br>
                        {t('addr2')} <a href="https://maps.app.goo.gl/LjpZ87u34CHi8GAH8" target="_blank" rel="noreferrer"><img alt='arrow' src={require('../../../img/arrow-right-orange-icon.svg').default} /> {t('seeonmap')}</a>
                    </div>

                    <div className='right' ref={popupTriggerButton} onClick={showFn}>
                        <p>{t('send')}</p>
                        <p>{t('message')}</p>
                    </div>
                </div>

                <div className='bot'>
                    <p>T: <a href='tel:+380674366677' >+ 38 067 436 66 77</a> </p>
                    <a href="https://www.facebook.com/lestroweb/?locale=ru_RU" target={"_blank"} className="soc" rel="noreferrer">FB</a>
                    <div className='rights'>{t('rights')} </div>
                </div>
            </div>

            <div className='succeded' ref={succedBlock}>
                <p>{t('formSent')}</p>
            </div>

            <div className="form-popup" id="form" ref={form}>
                <form ref={actualForm}>
                    <div className="close" onClick={showFn}>{t('close')}</div>

                    <div className="wrapper">
                        <div className='iw' ref={nameRef}>
                            <input ref={nameIn} type='text' placeholder={t('name')} required onChange={(e) => setName(e.target.value)} />
                            <div className='err empty'>{t('errorEmpty')}</div>
                        </div>
                        <div className='iw' ref={emailRef}>
                            <input ref={emailIn} type='text' placeholder="Email" required onChange={validateEmail} />
                            <div className='err empty'>{t('errorEmpty')}</div>
                            <div className='err incorrect'>{t('errorIncorrectMail')}</div>
                        </div>
                        <div className='iw' ref={subjectRef}>
                            <input ref={subjectIn} type='text' placeholder={t('subject')} required onChange={(e) => setSubject(e.target.value)} />
                            <div className='err empty'>{t('errorEmpty')}</div>
                        </div>
                        <div className='iw' ref={messageRef}>
                            <div ref={messageIn} className='err empty'>{t('errorLess')}</div>
                            <textarea placeholder={t('message')} ref={textarea} required onChange={handleTextarea} />
                        </div>

                        <div className="drag-n-drop-area" onDragOver={handleDragOver} onDrop={handleDrop} onDragLeave={handeDragLeave} onClick={() => fileInput.current.click()}>
                            {files === null ? (
                                <div ref={hoverArea} className={dragActive ? "drag-active w" : "w"}>
                                    <p className='fordesktop'>
                                        {t('dnd')}
                                    </p>
                                    <p className='formob'>{t('uf')}</p>
                                </div>
                            ) : (<div className='filelist'>
                                {
                                    Array.from(files).map((e, i) => {
                                        return <p key={i}>{e.name}</p>
                                    })}
                            </div>)
                            }
                            <input type="file" hidden ref={fileInput} onChange={(e) => setFiles(e.target.files)} />
                        </div>
                        <div className="cf-turnstile" data-sitekey="0x4AAAAAAASd2q0NcDEN2yJH"></div>
                        <div className="btn-send" onClick={submitForm}>{t('send')}</div>
                    </div>
                </form>
            </div>
        </footer>
    )
}

export default Footer;
