import { useEffect, useRef, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import LocalizedLink from "../../LocalizedLink";

const Menu = () => {
    const [activeSection, setActiveSection] = useState(null);
    const sections = useRef([]);
    const observerRef = useRef(null);
    const timeoutRef = useRef(null);
    const { t } = useTranslation();

    const handleIntersect = useCallback((entries) => {
        let visibleSections = [];

        entries.forEach(entry => {
            if (entry.isIntersecting) {
                visibleSections.push({ id: entry.target.id, ratio: entry.intersectionRatio, top: entry.boundingClientRect.top, height: entry.target.clientHeight });
            }
        });

        if (visibleSections.length > 0) {
            const centralPoint = window.innerHeight / 2;
            const centeredSection = visibleSections.reduce((closest, section) => {
                const sectionCenter = section.top + (section.height / 2);
                const distanceToCenter = Math.abs(sectionCenter - centralPoint);
                return distanceToCenter < closest.distance ? { ...section, distance: distanceToCenter } : closest;
            }, { distance: Infinity });

            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setActiveSection(centeredSection.id);
                timeoutRef.current = null;
            }, 300); // Затримка в 300 мс
        } else {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                setActiveSection(null);
                timeoutRef.current = null;
            }, 300); // Затримка в 300 мс
        }

        const atBottom = (window.innerHeight + window.scrollY) >= document.body.offsetHeight;
        if (atBottom && visibleSections.length > 0) {
            setActiveSection(visibleSections[visibleSections.length - 1].id);
        }
    }, []);

    const initIntersectionObserver = useCallback(() => {
        if (observerRef.current) {
            observerRef.current.disconnect();
        }

        observerRef.current = new IntersectionObserver(handleIntersect, {
            root: null,
            rootMargin: '0px',
            threshold: Array.from(Array(101).keys(), i => i / 100)
        });

        sections.current = document.querySelectorAll('[data-section]');
        sections.current.forEach(section => {
            observerRef.current.observe(section);
        });
    }, [handleIntersect]);

    useEffect(() => {
        initIntersectionObserver();

        const mutationObserver = new MutationObserver(() => {
            initIntersectionObserver();
        });

        mutationObserver.observe(document.body, {
            childList: true,
            subtree: true
        });

        const handleSectionsUpdated = () => {
            initIntersectionObserver();
        };

        window.addEventListener('sectionsUpdated', handleSectionsUpdated);

        return () => {
            observerRef.current && observerRef.current.disconnect();
            mutationObserver.disconnect();
            window.removeEventListener('sectionsUpdated', handleSectionsUpdated);
        };
    }, [initIntersectionObserver]);

    const clickHandle = (e) => {
        e.preventDefault();
        const position = document.getElementById('footer');
        if (position) {
            position.scrollIntoView({ behavior: "smooth", block: "start" });
        }
    };

    return (
        <div className='right'>
            <LocalizedLink path='/#about' text='menu-about' activeSection={activeSection} needToBeActive={'about'} />
            <a href='#footer' className={activeSection === 'footer' ? 'active anchor-link' : 'anchor-link'} onClick={clickHandle}>{t('menu-contact')}</a>
            <LocalizedLink path='/services' text='menu-services' activeSection={activeSection} needToBeActive={'services'} />
            <LocalizedLink path='/cases' text='menu-cases' activeSection={activeSection} needToBeActive={'cases'} />
            <LocalizedLink path='/#clients' text='menu-clients' activeSection={activeSection} needToBeActive={'clients'} />
            <LocalizedLink path='/blog' text='menu-blog' activeSection={activeSection} needToBeActive={'blog'} />
            {/* <LocalizedLink path='/team' text='menu-team' activeSection={activeSection} needToBeActive={'team'} /> */}
        </div>
    );
};

export default Menu;
