import $ from 'jquery'
import LocalizedLink from "../../LocalizedLink";
import MPCILink from "../../MPCILink";
export const MainPageServicesItem = ({ content, isMobile }) => {
    const HandleClick = (e) => {
        if ($(window).width() <= 768) {
            $('.section4 .item').find('span').slideUp(300)
            $('.section4 .item').find('.learn-more').slideUp(300)
            $('.section4 .item').find('p').removeClass('active')
            $(e.target).closest('.item').find('span').slideDown(300)
            $(e.target).closest('.item').find('.learn-more').slideDown(300)
            $(e.target).closest('.item').find('p').addClass('active')
        }
    }

    if (isMobile) {
        return (
            <div className='item' onClick={HandleClick}>
                <div className='container'>
                    <p>{content.name}</p>
                    <span>{content.mainPageContent}</span>

                    <LocalizedLink path={`/services${content.url}`} text='lm' clList='learn-more' />
                </div>
            </div>
        )
    }


    return (
        <MPCILink path={`/services${content.url}`} name={content.name} mainPageContent={content.mainPageContent} clList='item' onClick={HandleClick} />

    )
}
