export const ua = {
    "menu-about": "Про нас",
    "menu-services": "Послуги",
    "menu-cases": "кейси",
    "menu-clients": "клієнти",
    "menu-blog": "блог",
    "menu-contact": "контакти",
    "menu-team": "Команда",
    "creative": "креативний",
    "goodThings": "ГАРНІ речі",
    "digitalStudio": "діджитал Продакшен",
    "sol": "Розробляємо якісні диджитал-рішення для усіх ваших потреб",
    "slo": "У новому цифровому світі кожний бренд заслуговує на достойну репрезентацію. Ми знаємось на потрібних кодах та веб магії, щоб ви отримали найкращий продукт. ",
    "downloadCredentials": "Завантажити презентацію",
    "effective": "Кост-ефективність",
    "effectiveText": "Ми перший діджитал продакшен\nна ринку і ми знаємо як це робити",
    "exp": "Досвід",
    "expText": "Більше 1000 успішно завершених\nпроектів",
    "sust": "Сталість",
    "sustText": "Більше 13 років діяльності на ринку",
    "wd": "Веброзробки",
    "wdText": "Цифрові рішення від дизайну до фінальної публікації й техсупроводу",
    "md": "Мобайл",
    "mdText": "Продукти для iOS та Android",
    "cb": "Чат-боти",
    "cbText": "Прості та складні автоматизовані рішення для бізнесу",
    "lp": "Лендинги",
    "lpText": "Ефективні продукти для маркетингу та реклами",
    "banners": "Банери",
    "bannersText": "Дизайнимо та кодимо рекламні матеріали",
    "ts": "Техпідтримка",
    "tsText": "Вирішуємо поточні проблеми клієнтів з їх диджитал-продуктами",
    "endSlogan": "Ми любимо наші продукти та цінуємо своїх клієнтів. Працюємо з невичерпним ентузіазмом в усяких категоріях, з різними брендами та агенціями. Робимо швидко, якісно та результативно.",
    "lm": "Дізнатись більше",
    "close": "ЗАКРИТИ",
    "addr1": "УКРАЇНА, КИЇВ ПРОВУЛОК БЕХТЕРЕВСЬКИЙ, 4Б,",
    "addr2": "ОФІС 101",
    "rm": "Дізнатись більше",
    "errorEmpty": "Будь ласка заповніть це поле.",
    "errorIncorrectMail": "Будь ласка заповніть email коректно.",
    "errorLess": "Less than 15 characters.",
    "name": "Ім'я",
    "subject": "Тема",
    "message": "Повідомлення",
    "dnd": "Перетяніть файл",
    "uf": "Завантажити файл",
    "send": "Надіслати",
    "goto": "Перейти",
    "seeonmap": "Дивитись на мапі",
    "formSent" : "Відправлено",
    "brands" : "Бренди",
    "agencies" : "Агенції",
    "rights": "Всі права захищені",
    "PageNotFound" : "Сторінка не знайдена",
    "GoToHomePage": "ДО ГОЛОВНОЇ СТОРІНКИ",
    "teamHeading": "Наша команда",
    "teamPhilosophy":"НАША ФІЛОСОФІЯ ",
    "teamTitle": "За кожним продуктом, який ми створюємо, стоїть команда, яка прагне справити враження. Ми будуємо проекти, які виникають спонтанно з захопленням та талантом."
}
