import { Link } from "react-router-dom";
import i18n from 'i18next'
import { useTranslation } from "react-i18next";

const LocalizedLink = ({path, text, activeSection, imgSrc, needToBeActive}) => {

    const { t } = useTranslation();

    return (
        <Link className={activeSection === needToBeActive ? 'active' : ''} to={`${i18n.language === 'en' ? '' : '/ua'}${path}`}>
            {t(text)}
            <img src={imgSrc} alt=""/>
        </Link>
    )
}

export default LocalizedLink;
