import zirochka from '../../img/zirochka.svg'

import $ from 'jquery';
import { useEffect, useRef } from 'react';
import {useTranslation} from "react-i18next";
import { useSelector } from 'react-redux';

import CasesPage from './CasesPage/CasesPage'
import MoreBlog from '../partials/MoreBlog/MoreBlog'
import { LetterSloganContainer } from '../partials/LetterSlogan';
import { WeAreContainer } from '../partials/WeAre';
import { MainPageServices } from '../partials/MainPageServices/MainPageServices';
import HeaderHelmet from '../ui/HeaderHelmet';




function Home() {
    let aboutSection = useRef()
    let clientsSection = useRef();
    const themeColor = useSelector(state => state.languageSlice.themeColor)
    const language = useSelector(state => state.languageSlice.language)


    const showAgencies = () => {
        let titles = $('.section7 .container .wrapper p')
        $(titles[0]).removeClass('active')
        $(titles[1]).addClass('active')

        let lists = $('.section7 .container .wrapper .list')

        $(lists[0]).removeClass('active')
        $(lists[1]).addClass('active')
    }

    const showBrands = () => {
        let titles = $('.section7 .container .wrapper p')
        $(titles[1]).removeClass('active')
        $(titles[0]).addClass('active')

        let lists = $('.section7 .container .wrapper .list')

        $(lists[1]).removeClass('active')
        $(lists[0]).addClass('active')
    }

    let brandsImages = [];
    for (let i = 1; i <= 24; i ++){
        brandsImages.push(
            <div key={i} className='item'>
                <img  alt='logo' src={`/images/${!themeColor ? 'dark' : 'light'}/${i}.png`} srcSet={`/images/${!themeColor ? 'dark' : 'light'}/${i}@2x.png 2x`} />
            </div>
        )
    }

    let agensiesImages = [];
    for (let i = 25; i <= 48; i ++){
        agensiesImages.push(
            <div key={i} className='item'>
                <img  alt='logo' src={`/images/${!themeColor ? 'dark' : 'light'}/${i}.png`} srcSet={`/images/${!themeColor ? 'dark' : 'light'}/${i}@2x.png 2x`} />
            </div>
        )
    }

    useEffect(() => {
        let hash = window.location.hash.substr(1);

        const checkHash = (hash) => {
            if (hash === 'about') {
                let position = aboutSection.current;
                position && position.scrollIntoView({ behavior: "smooth", block: "start" })
            } else if (hash === 'clients') {
                let position = clientsSection.current;
                position && position.scrollIntoView({ behavior: "smooth", block: "start" })
            }
        }
        checkHash(hash);
    })

    const { t } = useTranslation();

    return (
        <div>
            <HeaderHelmet homepage={true}/>

            <section className="section1">
                <div className="container">
                    <div className="name">LESTRO</div>

                    <div className="main-title">
                        <h1>{t('creative')}</h1>
                        <h1 className="yellow" >{t('digitalStudio')}</h1>
                    </div>  

                    <div className="explanation"><img src={zirochka} alt='star'/> {t('sol')}</div>
                </div>
            </section>

            <section ref={aboutSection} className='section2' id='about' data-section={'#about'}>
                <div className='container'>
                    <div className='slogan' id='slogan1'>
                        <LetterSloganContainer text={t('slo')} />
                    </div>
                    <div className='btn-download-wrapper'>
                        <a href={`/presentation/Lestro_Presentation_${language === 'en' ? 'EN' : "UA"}.pdf`} target={"_blank"} className='download-credentials' rel="noreferrer">{t('downloadCredentials')}</a>
                        <div className='arrow1'><img alt="logo" src={require('../../img/arrow1.svg').default} /></div>
                        <div className='arrow2'><img alt="logo" src={require('../../img/arrow2.svg').default} /></div>
                        <div className='arrow3'><img alt="logo" src={require('../../img/arrow3.svg').default} /></div>
                    </div>
                </div>
            </section>

            <WeAreContainer />
            <MainPageServices />

            <CasesPage helmetHomePage={true}/>

            <section className='section6'>
                <div className='container'>
                    <h2><LetterSloganContainer text={t('endSlogan')} /></h2>
                </div>
            </section>


            <section ref={clientsSection} className='section7' id='clients' data-section={'#clients'}>
                <div className='container'>
                    <div className='wrapper'>
                        <p className='active' onClick={showBrands}>{t('brands')}</p>

                        <div className='list active'>
                            {brandsImages}
                        </div>
                    </div>

                    <div className='wrapper'>
                        <p onClick={showAgencies}>{t('agencies')}</p>
                        <div className='list '>
                            {agensiesImages}
                        </div>
                    </div>
                </div>
            </section>

            <MoreBlog/>
        </div>
    )
}

export default Home;
