import { useEffect, useRef } from 'react';
import $ from 'jquery'

import {useTranslation} from "react-i18next";

export const WeAreUa = () => {
    const ref1 = useRef(null);
    const we = useRef(null)
    const ar = useRef(null)
    const wrapper = useRef(null)

    const HandleClick = (e) => {
        if ($(window).width() <= 768) {
            $('.section3 .wrapper .item').find('span').slideUp(300)
            $('.section3 .wrapper .item').removeClass('active')

            $(e.target).closest('.item').find('span').slideDown(300)
            $(e.target).closest('.item').addClass('active')
        }
    }

    useEffect(() => {
        let windowHeight = window.innerHeight,
            elDistanceToTop = window.pageYOffset + ref1.current.getBoundingClientRect().top,
            scrollTop = (window.pageYOffset !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;

        // let animationHeight = windowHeight * .3;

        const scrollHandle = () => {
            scrollTop = (window.scrollY !== undefined) ? window.pageYOffset : (document.documentElement || document.body.parentNode || document.body).scrollTop;
            let delta = elDistanceToTop - (scrollTop + windowHeight * .4);
            let lam = windowHeight * .3;
            if (window.innerWidth > 768) {
                if (scrollTop + windowHeight * 0.4 <= elDistanceToTop && elDistanceToTop <= scrollTop + windowHeight * 0.9) {
                    we.current.style.transform = `rotate(${delta / lam * 15}deg)`;
                    we.current.style.left = `${delta / lam * -5.8449074074}vw`;

                    ar.current.style.top = `${delta / lam * 25.2314814815}vw`;
                    ar.current.style.left = `${44.4444444444 + (1 - (delta / lam)) * (52.6689814815 - 44.4444444444)}vw`;

                    wrapper.current.style.top = `${delta / lam * 16.2037037037 + 2.5}vw`;
                    wrapper.current.style.transform = `rotate(${delta / lam * -6.05}deg)`;
                    wrapper.current.style.left = `${65.6828703704 + (1 - (delta / lam)) * (72.6689814815 - 65.6828703704)}vw`;
                } else {
                    if (scrollTop + windowHeight * 0.4 >= elDistanceToTop){
                        we.current.style.transform = `rotate(0deg)`;
                        we.current.style.left = `0`;

                        ar.current.style.top = `0vw`;
                        ar.current.style.left = `52.6vw`;

                        wrapper.current.style.top = `0vw`;
                        wrapper.current.style.transform = `rotate(0deg)`;
                        wrapper.current.style.left = `72.6689814815vw`;
                    }
                }
            } else {

                if (scrollTop + windowHeight * 0.4 <= elDistanceToTop && elDistanceToTop <= scrollTop + windowHeight * 0.9) {
                    we.current.style.transform = `rotate(${delta / lam * 15}deg)`;
                    we.current.style.left = `${2 + delta / lam * (4 - 8.6046511628)}vw`;

                    ar.current.style.top = `${delta / lam * 23.2558139535 + 6.976744186}vw`;
                    ar.current.style.left = `${60.4651162791 + (1 - (delta / lam)) * (74 - 60.4651162791)}vw`;
                } else {
                    if (elDistanceToTop <= scrollTop + windowHeight * 0.9) {
                        we.current.style.transform = `rotate(0deg)`;
                        we.current.style.left = `2vw`;

                        ar.current.style.top = `6.976744186vw`;
                        ar.current.style.left = `74vw`;

                        wrapper.current.style.top = `6.976744186vw`;
                        wrapper.current.style.transform = `rotate(0deg)`;
                        wrapper.current.style.left = `72.6689814815vw`;
                    }
                }
            }
        }
        window.addEventListener('scroll', scrollHandle)

        scrollHandle();

        return () => {
            window.removeEventListener('scroll', scrollHandle);
        }
    })
    const { t } = useTranslation();

    return (
        <section className='section3' ref={ref1}>
            <div className='weua' ref={we}>
                <img src={require('../../../img/we-ua.svg').default} alt='we'/>
            </div>
            <div className='arua' ref={ar}>
                <img src={require('../../../img/are-ua.svg').default} alt='ar'/>
            </div>

            <div className='wrapper ua' ref={wrapper}>
                <div className='item' onClick={HandleClick}>
                    <p><img src="/images/effective-icon.svg" alt=""/> {t('effective')}</p>
                    <span>{t('effectiveText')}</span>
                </div>

                <div className='item' onClick={HandleClick}>
                    <p><img src="/images/exp-icon.svg" alt=""/>{t('exp')}</p>
                    <span>{t('expText')}</span>
                </div>

                <div className='item' onClick={HandleClick}>
                    <p><img src="/images/sust-icon.svg" alt=""/>{t('sust')}</p>
                    <span>{t('sustText')}</span>
                </div>
            </div>
        </section>
    )
}
