import { useEffect } from "react";
import { useLocation } from "react-router";

export default function ScrollToTop() {
    const pathname = useLocation();
    useEffect(() => {
        if (pathname.hash === '') {
            window.scrollTo(0, 0)
        } else if (pathname.hash === '#about') {
            document.querySelector('#about').scrollIntoView({
                behavior: 'smooth'
            });
        } else if (pathname.hash === '#clients') {
            document.querySelector('#clients').scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [pathname])
}